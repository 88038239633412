import React from 'react'
import Layout from '../components/layout/layout'
import Footer from '../components/layout/footer'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

export default function OfflinePage() {
  return (
    <Layout>
      <GatsbySeo
        titleTemplate='Offline | %s'
      />
      <div className="main-container">
        <header className="text-white">
          <div className="d-flex justify-content-center sm-adjust page-not-found">
            <h1>You are offline :(</h1>
          </div>
        </header>
        <Footer />
      </div>
    </Layout>
  )
}
